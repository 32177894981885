import axios, { baseURL } from "@axios";
import { paginateArray, sortCompare } from "@/common";
import { getUserData } from "@/auth/utils";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    
    fetchSubinventorys(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/SubInventory/GetByKey?key=${queryParams.q}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Order/GetByKey?key=${queryParams.q}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Order/GetById`, {
            params: {
              orderId: id,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getUser(ctx, queryParams) {
      const payload = {
        key: queryParams,
        dateFrom: null,
        dateTo: null,
        status: null,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/User/GetByKey`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getRole() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Role/GetByKey`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
